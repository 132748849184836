<template>
  <base-section id="k-d-a-process-header" class="white overflow-hidden" space="0">
    <div class="d-flex justify-center align-center py-16 py-md-15" style="position: relative">
      <h1
        :class="`kda-ts-${g_bLowerBr ? '28' : '64'}pt wt-extrabold`"
        style="position: relative; z-index: 1"
      >
        {{ strTitle }}
      </h1>

      <img :src="imgSrc" style="position: absolute; left: 0; right: 0; width: 100%" />
    </div>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDAProcessHeader',

  mixins: [BaseBreakpoint],

  props: {
    strTitle: {
      type: String,
      default: ''
    },
    imgSrc: {
      type: String,
      default: ''
    }
  }
};
</script>
